// This file was generated during build from environment variables.
export const apiEndpoint = "https://integrationtest.galileo-ge2c-api.careevolution.com";
export const oauthClientId = "2cdoo5henetudnloma0o98vh50";
export const oauthScopes = ["openid","email","profile"];
export const oauthURLs = {
  "authorize": "https://ge2c-galileo-integrationtest.auth.us-east-1.amazoncognito.com/oauth2/authorize",
  "token": "https://ge2c-galileo-integrationtest.auth.us-east-1.amazoncognito.com/oauth2/token",
  "signout": "https://ge2c-galileo-integrationtest.auth.us-east-1.amazoncognito.com/logout"
};
export const validationsEnabled = true;
